import React, { Fragment, useRef } from "react";

import MainMenu from "../../Components/Menu";
import Footer from "../../Components/Footer/";
import { LeftRightContentBlock, LeftRightContentTitle } from "../../Components/ContentBlock"
import HeroBannerImage from "../../static/images/hero-banner.png";
import UserProfileImage from "../../static/images/user-profile.png";
import ChecklistImage from "../../static/images/checklist.png";
import CalculatorImage from "../../static/images/calculator.png";
import eLearningImage from "../../static/images/elearning.png";
import HelpCenterImage from "../../static/images/help-center.png";
import elevatePartner from "../../static/partners/elevate-logo.svg";
import diginexPartner from "../../static/partners/diginex-logo.svg";
import winrockPartner from "../../static/partners/winrock-logo.png";
import gfemsPartner from "../../static/partners/gfems-logo.svg";
import Slider from '../../Components/Slider'

const LandingPage = () => {
  const refuserProfile = useRef();
  const refmigrationChecklist = useRef();
  const refbudgetCalculator = useRef();
  const refeLearning = useRef();
  const refhelpCenter = useRef();
  const refdownloadApp = useRef();

  
  const scrollToRefuserProfile = () => refuserProfile.current.scrollIntoView();
  const scrollToRefmigrationChecklist = () =>
  refmigrationChecklist.current.scrollIntoView();
  const scrollToRefbudgetCalculator = () =>
    refbudgetCalculator.current.scrollIntoView();
  const scrollToRefeLearning = () =>
    refeLearning.current.scrollIntoView();
    const scrollToRefhelpCenter = () =>
    refhelpCenter.current.scrollIntoView();
    const scrollToRefdownloadApp = () =>
    refdownloadApp.current.scrollIntoView();

    const cards = [
      {
        image: 'https://www.elevatelimited.com/wp-content/uploads/2021/07/SafeStep_Blog_HeaderImage.jpg',
        title: 'Transforming the international migration and recruitment system',
        link: 'https://www.elevatelimited.com/blog/safestep-transforming-the-international-migration-and-recruitment-system/',
      },
      {
        image: 'https://www.elevatelimited.com/wp-content/uploads/2023/08/migrant-workers-1.jpg',
        title: 'Navigating Human Rights Challenges with the SafeStep App',
        link: 'https://www.elevatelimited.com/blog/managing-responsible-recruitment-navigating-human-rights-challenges-with-the-safestep-app/',
      },
      {
        image: 'https://www.elevatelimited.com/wp-content/uploads/2022/08/Blog-SafeStep-1170x500-1.jpg',
        title: 'Engaging Employers to Make Migration Safer',
        link: 'https://www.elevatelimited.com/blog/engaging-employers-to-make-migration-safer/',
      },
      {
        image: 'https://www.elevatelimited.com/wp-content/uploads/2023/02/SafeStep-blog-post-2-banner.jpg',
        title: 'How to build an ethical recruitment program',
        link: 'https://www.elevatelimited.com/blog/how-to-build-an-ethical-recruitment-program/',
      },
    ];

    return (
      <Fragment>
      <div className="flex flex-col mx-auto">
        <div className="bg-skyblue">
          <MainMenu
            scrollToRefuserProfile={scrollToRefuserProfile}
            scrollToRefmigrationChecklist={scrollToRefmigrationChecklist}
            scrollToRefbudgetCalculator={scrollToRefbudgetCalculator}
            scrollToRefeLearning={scrollToRefeLearning}
            scrollToRefhelpCenter={scrollToRefhelpCenter}
            scrollToRefdownloadApp={scrollToRefdownloadApp}
          />
          <div className="pt-[81px]">
            <LeftRightContentBlock
              leftWidth="md:w-[32%] w-full"
              rightWidth="md:w-[67%] w-full"
              center="items-center"
              direction="flex-col-reverse md:flex-row"
              left={
                <div className="flex justify-center">
                  <img src={HeroBannerImage} alt="hero banner" />
                </div>
              }
              right={
                <div className="section-motion-graphic">
                  <Fragment>
                    <LeftRightContentTitle
                      mainTitle="Empowering Migrant Workers
                      To Make Informed Decisions"
                    />
                    <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                      <p className="description">
                        SafeStep empowers workers to make informed decisions
                        about migrating to work overseas by providing
                        accessible, accurate information on what to expect
                        during the recruitment process.
                      </p>
                    </div>
                  </Fragment>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div ref={refuserProfile} className="scroll-offset"></div>
        <section className="py-[30px] md:py-[100px]">
          <LeftRightContentBlock
            left={
              <Fragment>
                <LeftRightContentTitle
                  subtitle="User Profile"
                  mainTitle="Add in skills to be
                  matched with
                  prospective employers"
                />
                <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                  <p className="description">
                    Migrant workers who already have prior work experience can
                    add this information to their profile and increase potential
                    employability. They can also add in various professional
                    skills which will match potential employers needs with
                    workers who have the relevant skills.
                  </p>
                </div>
              </Fragment>
            }
            right={
              <div className="flex justify-center">
                <img src={UserProfileImage} alt="user profile" />
              </div>
            }
          />
        </section>
        <div ref={refmigrationChecklist} className="scroll-offset"></div>
        <section className="py-[30px] md:py-[100px] bg-lightgray">
          <LeftRightContentBlock
            direction="flex-col-reverse md:flex-row"
            left={
              <div className="flex justify-center">
                <img src={ChecklistImage} alt="Migration Checklist" />
              </div>
            }
            right={
              <Fragment>
                <LeftRightContentTitle
                  subtitle="Migration Checklist"
                  mainTitle="Guided checklist for items
                  required as part of working
                  overseas"
                />
                <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                  <p className="description">
                    The migration checklist lists and stores all required
                    documentation needed by employers and the government for the
                    recruitment and visa application process and offers safe
                    storage of all financial documents and receipts.
                  </p>
                </div>
              </Fragment>
            }
          />
        </section>
        <div ref={refbudgetCalculator} className="scroll-offset"></div>
        <section className="py-[30px] md:py-[100px]">
          <LeftRightContentBlock
            left={
              <Fragment>
                <LeftRightContentTitle
                  subtitle="Budget Calculator"
                  mainTitle="See what migration fees
                  others have paid before you"
                />
                <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                  <p className="description">
                    The SafeStep budget calculator helps workers understand the
                    “true” cost of migration and assess relative to benefits to
                    help workers make informed decisions.
                  </p>
                </div>
              </Fragment>
            }
            right={
              <div className="flex justify-center">
                <img src={CalculatorImage} alt="Budget Calculator" />
              </div>
            }
          />
        </section>
        <div ref={refeLearning} className="scroll-offset"></div>
        <section className="py-[30px] md:py-[100px] bg-lightgray">
          <LeftRightContentBlock
            direction="flex-col-reverse md:flex-row"
            left={
              <div className="flex justify-center">
                <img src={eLearningImage} alt="eLearning" />
              </div>
            }
            right={
              <Fragment>
                <LeftRightContentTitle
                  subtitle="eLearning"
                  mainTitle="Custom, pre-decision and 
                  pre-departure eLearning
                  modules"
                />
                <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                  <p className="description">
                    The app includes a series of custom, pre-decision and
                    pre-departure eLearning modules for migrant workers. An
                    interactive help function featuring dynamic FAQs addresses
                    migration corridor questions.
                  </p>
                </div>
              </Fragment>
            }
          />
        </section>
        <div ref={refhelpCenter} className="scroll-offset"></div>
        <section className="py-[30px] md:py-[100px]">
          <LeftRightContentBlock
            left={
              <Fragment>
                <LeftRightContentTitle
                  subtitle="Help Center"
                  mainTitle="Real-time responses
                  to key questions and
                  concerns"
                />
                <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                  <p className="description">
                    Migrant workers can access live support through the help
                    center powered by artificial intelligence. The question bank
                    is regularly updated to ensure the app provides the
                    information workers need.
                  </p>
                </div>
              </Fragment>
            }
            right={
              <div className="flex justify-center">
                <img src={HelpCenterImage} alt="Help Center" />
              </div>
            }
          />
        </section>

        <section className="pt-[30px] md:py-[30px] md:py-[70px] bg-[#495988]">
          <Slider />
        </section>


        <section className="py-[30px] md:py-[100px] bg-lightgray">
          <LeftRightContentBlock
            leftWidth="w-full"
            left={
              <div className="partner-support">
                <div className="flex justify-between">
                  <div className="flex-item">
                    <div className="partner-text">
                      <p>SafeStep Consortium Partners:</p>
                    </div>
                    <img src={elevatePartner} alt="elevate logo" />
                  </div>
                  <div className="flex-item">
                    <img src={diginexPartner} alt="diginex logo" />
                  </div>
                  <div className="flex-item items-center">
                    <img src={winrockPartner} alt="diginex logo" />
                  </div>
                  <div className="flex-item">
                    <div className="partner-text">
                      <p>Funding and Support Provided By:</p>
                    </div>
                    <img src={gfemsPartner} alt="elevate logo" />
                  </div>
                </div>
              </div>
            }
          />
        </section>

        <div ref={refdownloadApp} className="scroll-offset"></div>

        <section className="py-[20px] md:py-[60px] px-[20px] bg-white">
          <div className="w-full flex justify-center">
          <h2 className="mainTitle mb-[40px]">Blog</h2> 
          </div>
        
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {cards.map((card, index) => (
              <a href={card.link} target="_blank" rel="noreferrer" key={index}>
                <div className="relative h-[300px]">
                  <img
                    src={card.image}
                    alt={card.title}
                    className="absolute inset-0 w-full h-full object-cover object-center"
                  />
                  <div className="bg-[#E32332] px-2.5 absolute top-5 left-8 py-2.5 z-10">
                    <span className="text-white">Blog</span>
                  </div>
                  <div className="absolute inset-0 bg-black bg-opacity-40 hover:bg-red flex items-center justify-center p-[30px]">
                    <h2 className="text-white text-2xl font-semibold">{card.title}</h2>
                  </div>
                </div>
              </a>
            ))}
          </div>

        </section>


        <Footer />
      </div>
    </Fragment>
  );
};

export default LandingPage;
