import React from "react";
import { Disclosure } from "@headlessui/react";

const NavLinks = (props) => {
  const {
    scrollToRefuserProfile,
    scrollToRefmigrationChecklist,
    scrollToRefbudgetCalculator,
    scrollToRefeLearning,
    scrollToRefhelpCenter,
  } = props;
  const navigation = [
    { text: "User Profile", scrollTo: scrollToRefuserProfile },
    { text: "Migration Checklist", scrollTo: scrollToRefmigrationChecklist },
    { text: "Budget Calculator", scrollTo: scrollToRefbudgetCalculator },
    { text: "eLearning", scrollTo: scrollToRefeLearning },
    { text: "Help Center", scrollTo: scrollToRefhelpCenter },
  ];
  const email = 'info@elevatelimited.com';
  const subject = 'Demo Request';
  const body = encodeURIComponent('Hi, I would like to request a demo for the SafeStep app. Thanks!');

  const handleEmailButtonClick = () => {
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${body}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      <div className="flex flex-auto items-center flex-col lg:flex-row border-t-1 lg:border-0 border-light2">
        <div className="w-full flex justify-center flex-col lg:flex-row mr-0 xl:mr-4 2xl:mr-5">
          {navigation.map((nav, index) => (
            <Disclosure.Button
              key={index}
              onClick={nav.scrollTo}
              className="main-menu-link border-light2 whitespace-nowrap p-3 lg:p-3 pl-4 xl:pl-7 text-left lg:text-center border-b lg:border-none "
            >
              {nav.text}
            </Disclosure.Button>
          ))}
        </div>
      </div>
      <div className="lg:flex whitespace-nowrap flex-col hidden lg:flex-row lg:justify-end px-4 pt-4 lg:pt-0 lg:px-0">
        <a href="!#" className="btn !text-[#3365d8] border !border-[#3365d8] bg-[#dcf4fe] btn-primary mr-2 cursor-pointer" onClick={handleEmailButtonClick}>Request Demo</a>

      </div>
    </>
  );
};

export default NavLinks;
