import React from "react";
import logoEiQ from "../../static/logo/logo.png";
import { Disclosure } from "@headlessui/react";
import NavLinks from "./MenuLinks";
const MainMenu = (props) => {
  const {
    scrollToRefuserProfile,
    scrollToRefmigrationChecklist,
    scrollToRefbudgetCalculator,
    scrollToRefeLearning,
    scrollToRefhelpCenter,
    scrollToRefdownloadApp,
  } = props;
  return (
    <>
      <Disclosure as="nav" className="fixed bg-skyblue top-[0px] w-full z-50">
        {({ open }) => (
          <>
            <div className="mx-auto menu border-b-1 lg:border-b-0 border-custom-gray max-w-[1440px] lg:px-[20px] xl:px-[60px] mx-auto w-full">
              <div className="z-50 w-full px-7 lg:px-0 py-4 relative flex items-center justify-end">
                <div className="">
                  <img src={logoEiQ} alt="EiQ" className="w-32" />
                </div>
                <div className="absolute toggle-btn inset-y-0  flex items-center lg:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-x"
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        strokeWidth="2.5"
                        stroke="#2b80ff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-menu-2"
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        strokeWidth="2.5"
                        stroke="#2b80ff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="4" y1="6" x2="20" y2="6" />
                        <line x1="4" y1="12" x2="20" y2="12" />
                        <line x1="4" y1="18" x2="20" y2="18" />
                      </svg>
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-between">
                  <div className="hidden lg:flex sm:ml-6 w-full">
                    <NavLinks
                      scrollToRefuserProfile={scrollToRefuserProfile}
                      scrollToRefmigrationChecklist={scrollToRefmigrationChecklist}
                      scrollToRefbudgetCalculator={scrollToRefbudgetCalculator}
                      scrollToRefeLearning={scrollToRefeLearning}
                      scrollToRefhelpCenter={scrollToRefhelpCenter}
                      scrollToRefdownloadApp={scrollToRefdownloadApp}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="mobile-menu lg:hidden">
              <NavLinks
                scrollToRefuserProfile={scrollToRefuserProfile}
                scrollToRefmigrationChecklist={scrollToRefmigrationChecklist}
                scrollToRefbudgetCalculator={scrollToRefbudgetCalculator}
                scrollToRefeLearning={scrollToRefeLearning}
                scrollToRefhelpCenter={scrollToRefhelpCenter}
                scrollToRefdownloadApp={scrollToRefdownloadApp}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default MainMenu;
