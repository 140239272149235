import React from "react";

const LeftRightContentBlock = ({
  center,
  color,
  direction,
  left,
  right,
  leftWidth,
  rightWidth,
}) => {
  const sectionDirection = direction ? direction : "";
  const sectionColor = color ? color : "";
  const centerAlign = center ? "items-center" : "";
  const centerJustify = center ? "justify-center" : "";
  const leftContentWidth = leftWidth ? leftWidth : "md:w-[49%] w-full";
  const rightContentWidth = rightWidth ? rightWidth : "md:w-[49%] w-full";

  return (
    <div
      className={`section max-w-[1340px] md:px-[20px] xl:px-[60px] mx-auto w-full justify-between flex-col md:flex-row gap-x-3 ${sectionColor} ${sectionDirection} ${centerAlign} ${centerJustify}`}
    >
      {left && (
        <div className={`${leftContentWidth}`}>{left}</div>
      )}
      {right && <div className={`${rightContentWidth}`}>{right}</div>}
    </div>
  );
};

export default LeftRightContentBlock;
