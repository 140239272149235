import React from "react";

const LeftRightContentTitle = ({subtitle, mainTitle }) => {
  return (
    <div className="section-labels">
      {subtitle && <span className="subTitle"> {subtitle}</span> }
      {mainTitle && <h2 className="mainTitle">{mainTitle}</h2> }
    </div>
  );
};

export default LeftRightContentTitle;