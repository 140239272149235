import React from "react";

import logoElevate from "../../static/logo/logo-lrqa.svg";
import iconFacebook from "../../static/icons/icon-facebook.svg";
import iconLinkedin from "../../static/icons/icon-linkedin.svg";
import iconTwitter from "../../static/icons/icon-twitter.svg";
import iconYoutube from "../../static/icons/icon-youtube.svg";
const Footer = () => {

  return (
    <div className="footer flex py-[20px] px-[0px] mdx:px-[40px] lg-px-[60px] xl:px-90px md:py-[40px] xl:py-60px">
      <div className="flex flex-col w-full">
        <div className="flex items-center md:items-start flex-col px-[20px] mdx:px-[0] ">
          <div className="footer-social-media">
            <a href="https://www.elevatelimited.com/">
              <img src={logoElevate} alt="Elevate" width={200} />
            </a>
            <a href="https://www.linkedin.com/company/elevate-global-limited/">
              <img src={iconLinkedin} alt="Linkedin" />
            </a>
            <a href="https://twitter.com/ElevateLimited">
              <img src={iconTwitter} alt="Twitter" />
            </a>
            <a href="https://www.facebook.com/Elevate.Global.Limited">
              <img src={iconFacebook} alt="Facebook" />
            </a>
            <a href="https://www.youtube.com/user/ElevateLimited">
              <img src={iconYoutube} alt="Youtube" />
            </a>
          </div>
          <div className="footer-copyright">© ELEVATE 2023</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
