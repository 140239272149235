import React, { useRef, useCallback, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import cs1_graphic_mobile from '../../static/case-studies/cs1_graphic_mobile.svg'
import cs2_graphic_mobile from '../../static/case-studies/cs2_graphic_mobile.svg'
import cs3_graphic_mobile from '../../static/case-studies/cs3_graphic_mobile.svg'
import cs4_graphic_mobile from '../../static/case-studies/cs4_graphic_mobile.svg'
import cs5_graphic_mobile from '../../static/case-studies/cs5_graphic_mobile.svg'
import nextbuttom from '../../static/case-studies/nextbuttom.svg' 
import nextbuttomhover from '../../static/case-studies/nextbuttomhover.svg' 
import previousbuttom from '../../static/case-studies/previousbuttom.svg'
import previousbuttomhover from '../../static/case-studies/previousbuttomhover.svg'
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Slider = () => {
    const sliderRef = useRef(null);
    const [isNextHovered, setIsNextHovered] = useState(false);
    const [isPrevHovered, setIsPrevHovered] = useState(false);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
      }, []);
    
      const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
      }, []);

  const swiperParams = {
    autoplay: {
        delay: 30000,
        disableOnInteraction: true,
    },
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: "bullets",
        bulletClass: "swiper-custom-bullet",
        bulletActiveClass: "swiper-custom-bullet-active",
        clickable: true,
    },
    // breakpoints: {
    //     // when window width is <= 499px
    //     499: {
    //         slidesPerView: 1.2,
    //         spaceBetweenSlides: 30
    //     },
    // }
  };

  return (
    <div className="relative">
        <div className="px-[20px] md:px-[80px]">
        <Swiper {...swiperParams} ref={sliderRef} modules={[Autoplay, Pagination, Navigation]}>
        <SwiperSlide>
            <div className="swiper-slide">
                <div className="section max-w-[1340px] md:px-[20px] xl:px-[60px] mx-auto w-full justify-between flex-col md:flex-row gap-x-3 flex-col-reverse md:flex-row items-stretch">
                    <div className="md:w-[40%] w-full p-4">
                    <img src={cs1_graphic_mobile} alt="diginex logo" className="w-full h-full object-cover md:object-contain object-center" />  
                    </div>
                    <div className="md:w-[60%] w-full">
                        <div className="section-labels">
                        <span className="subTitle text-[#89a3c3]">Case Study #1</span>
                        <h2 className="mainTitle">Empowering migrant workers</h2>
                        </div>
                        <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                        <p className="description text-white">Rakibul, a twenty-eight-year-old  Bangladeshi migrant worker, was initially concerned about fraud and exploitation during his recruitment process for a road cleaning job in Saudi Arabia. However, he  found reassurance through the SafeStep App. The App provided him with resources and vital communication channels, including  the Ministry of Expatriate Welfare and Overseas Employment (MoEWOE) and the Saudi Arabia police contact details, via the Help Center chatbot.  Feeling empowered and secure, Rakibul informed the sub-agent that any fraud could be reported directly to these entities. ​<br/>​<br/>
Today, Rakibul works diligently in Saudi Arabia and secure in the knowledge that he can assert his rights and protect himself against exploitation.</p>
                        </div>
                    </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="swiper-slide">
                <div className="section max-w-[1340px] md:px-[20px] xl:px-[60px] mx-auto w-full justify-between flex-col md:flex-row gap-x-3 flex-col-reverse md:flex-row items-stretch">
                <div className="md:w-[40%] w-full p-4">
                    <img src={cs2_graphic_mobile} alt="diginex logo" className="w-full h-full object-cover md:object-fill object-center" />  
                    </div>
                    <div className="md:w-[60%] w-full">
                        <div className="section-labels">
                        <span className="subTitle text-[#89a3c3]">Case Study #2</span>
                        <h2 className="mainTitle">Obtaining A Loan ​</h2>
                        </div>
                        <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                        <p className="description text-white">Kamal is a twenty-year-old man from Tangail district in Bangladesh. He was contemplating working abroad in hospitality in Bahrain and utilized SafeStep's e-Learning videos and the Budget Calculator to do a cost-benefit analysis. He was able to calculate the total migration cost and the expected income from his overseas employment over a specific period. He also compared his current income to the projected income and concluded that the migration was financially viable, providing him with the best financial income. ​​<br/><br/>Moreover, the SafeStep's Help Center chatbot directed him to Probashi Kallyan Bank, where he secured a low-interest loan. By understanding that individual moneylenders (Dadons and Mahajans) and other sources charge higher interest rates and compound interest than the bank for loans, Kamal was able to avoid a potential debt-bondage situation. </p>
                        </div>
                    </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="swiper-slide">
                <div className="section max-w-[1340px] md:px-[20px] xl:px-[60px] mx-auto w-full justify-between flex-col md:flex-row gap-x-3 flex-col-reverse md:flex-row items-stretch">
                <div className="md:w-[40%] w-full p-4">
                    <img src={cs3_graphic_mobile} alt="diginex logo" className="w-full h-full object-cover md:object-fill object-center" />  
                    </div>
                    <div className="md:w-[60%] w-full">
                        <div className="section-labels">
                        <span className="subTitle text-[#89a3c3]">Case Study #3</span>
                        <h2 className="mainTitle">Recruitment Agency Verification ​</h2>
                        </div>
                        <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                        <p className="description text-white">Minarul is from a small rural village in northern Bangladesh where he was struggling to support his family financially. He was exploring a construction job opportunity in Oman that promises a better salary and used the SafeStep App to understand the complexities of overseas labour recruitment. ​​<br/><br/>Through the e-learning video on Pre-departure: 10 Steps to Safe Migration (Bengali version) and field facilitators from Refugee and Migratory Movements’ Research Unit field (SafeStep implementing partner), Minarul discovered the importance of verifying the licenses of recruitment agencies. He used SafeStep's Help Center chatbot which enabled him to confirm his agency's credibility. By ensuring he was using a licensed agency, Minarul was assured that the agency follows local laws and will be closely monitored by authorities to ensure proper recruitment practices. </p>
                        </div>
                    </div>
                </div>
            </div> 
        </SwiperSlide>
        <SwiperSlide>
        <div className="swiper-slide">
                <div className="section max-w-[1340px] md:px-[20px] xl:px-[60px] mx-auto w-full justify-between flex-col md:flex-row gap-x-3 flex-col-reverse md:flex-row">
                <div className="md:w-[40%] w-full p-4">
                    <img src={cs4_graphic_mobile} alt="diginex logo" className="w-full h-full object-cover md:object-fill object-center" />  
                    </div>
                    <div className="md:w-[60%] w-full">
                        <div className="section-labels">
                        <span className="subTitle text-[#89a3c3]">Case Study #4</span>
                        <h2 className="mainTitle">Repatriation Support​</h2>
                        </div>
                        <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                        <p className="description text-white">Tanvir, a Bangladeshi migrant worker, fell sick while working as a toilet cleaner in Dubai. He was taken to Aster Hospital where he had to pay 60% of his medical bill. Unable to afford living in Dubai, he requested to go home immediately to seek the proper medical treatment. However, the recruitment agency demanded a hefty repatriation fee from Tanvir. ​<br/>Tanvir turned to the SafeStep app, contacting the Suara Kami Helpline. The Helpline connected him with an organisation specializes in labour exploitation and human trafficking. They mediated with the recruitment agency and Tanvir returned quickly and safely to Bangladesh without paying the repatriation fees. Furthermore, a Bangladeshi NGO provided Tanvir and his family with counselling and support, empowering them against the recruitment agency’s threats.</p>
                        </div>
                    </div>
                </div>
            </div> 
        </SwiperSlide>
        <SwiperSlide>
        <div className="swiper-slide">
                <div className="section max-w-[1340px] md:px-[20px] xl:px-[60px] mx-auto w-full justify-between flex-col md:flex-row gap-x-3 flex-col-reverse md:flex-row items-stretch">
                <div className="md:w-[40%] w-full p-4">
                    <img src={cs5_graphic_mobile} alt="diginex logo" className="w-full h-full object-cover md:object-fill object-center" />  
                    </div>
                    <div className="md:w-[60%] w-full">
                        <div className="section-labels">
                        <span className="subTitle text-[#89a3c3]">Case Study #5</span>
                        <h2 className="mainTitle">Reimbursement Support​</h2>
                        </div>
                        <div className="section-text-xl-blue mt-[22px] mb-[38px]">
                        <p className="description text-white">Akbar, a Bangladeshi migrant worker, reported that his salary has been illegal deducted for his visa renewal during the rehiring process. Additionally when Akbar requested his passport back from the manufacturing company, they demanded a fee. Akbar refused and reported this incident through the Suara Kami Helpline on SafeStep. ​​<br/><br/>The case was then investigated by North South Initiative (NSI)  where they were able to prove that Akbar’s salary was deducted and passport held by the company. NSI advised the company that these activities are   illegal and requested that the company return the amount that had been deducted for visa renewal and the  passport. The company complied, returning Akbar’s passport and the right imbursement amount.  On receiving his passport and reimbursement, Akbar decided to leave the company and look for other means of work.</p>
                        </div>
                    </div>
                </div>
            </div> 
        </SwiperSlide>
        </Swiper>
        </div>
        <div className="swiper-pagination"></div>
        <div className="prev-arrow absolute left-5 top-[50%] bottom-0 mx-auto my-0 cursor-pointer swiper-button-prev z-50 hidden md:block" onClick={handlePrev} onMouseEnter={() => setIsPrevHovered(true)}
            onMouseLeave={() => setIsPrevHovered(false)}>
            <img src={isPrevHovered ? previousbuttomhover : previousbuttom} alt="prev-slide" className="cursor-pointer" />
        </div>  
        <div
            className="next-arrow absolute right-5 top-[50%] bottom-0 mx-0 my-auto cursor-pointer swiper-button-next z-50 hidden md:block" onClick={handleNext}
            onMouseEnter={() => setIsNextHovered(true)}
            onMouseLeave={() => setIsNextHovered(false)}
            >
            <img
                src={isNextHovered ? nextbuttomhover : nextbuttom}
                alt="next-slide"
                className="cursor-pointer"
            />
        </div>
    </div>
  );
};

export default Slider;
